<template>
  <div class="base-image-rotator">
    <div class="base-image">
      <img
        :class="currentClass"
        :src="imgSrc"
        :style="{transform: rotateStyle}"
      />
    </div>
  </div>
</template>

<style scoped>
  .portrait {
    max-width: 340px;
    height: auto;
  }
  .landscape {
    max-height: 480px;
    width: auto;
  }
</style>

<script>
export default {
  name: 'BaseImageRotator',
  props: {
    imgSrc: String,
    rotate: Number,
    reset: Boolean,
  },
  data: () => ({
    rotateDeg: 360,
    rotateStyle: '',
    currentRotate: 0,
    portrait: {
      width: 400,
    },
    landscape: {
      width: 650,
    },
    isPortrait: true,
    isLandscape: false,
  }),
  computed: {
    currentClass() {
      return {
        portrait: this.isPortrait,
        landscape: this.isLandscape,
      };
    },
  },
  watch: {
    rotate(num) {
      if (Math.abs(this.rotateDeg) === 360) {
        this.rotateDeg = 0;
      }

      if (num > this.currentRotate) {
        this.rotateDeg += 90;
      } else {
        this.rotateDeg -= 90;
      }
      this.rotateStyle = 'rotate('.concat(this.rotateDeg).concat('deg)');
      this.currentRotate = num;
      this.sendDimensions(this.rotateDeg);
    },
    reset() {
      this.rotateStyle = 'rotate(0deg)';
    },
  },
  methods: {
    sendDimensions(deg) {
      const degs = {
        0: () => this.setPortrait(),
        90: () => this.setLandscape(),
        180: () => this.setPortrait(),
        270: () => this.setLandscape(),
        360: () => this.setPortrait(),
      };
      return (degs[deg])();
    },
    setPortrait() {
      this.isPortrait = true;
      this.isLandscape = false;
      this.$emit('dimensionChange', this.portrait);
    },
    setLandscape() {
      this.isPortrait = false;
      this.isLandscape = true;
      this.$emit('dimensionChange', this.landscape);
    },
  },
};
</script>
